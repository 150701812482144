<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-row class="px-4">
                        <v-col cols="12" md="4">
                            <DatePicker
                                :value="filter.from"
                                label="Issued after"
                                @save="updateFrom"
                                show-clear
                                hide-details
                                dense
                                :loading="loading"
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <DatePicker
                                :value="filter.to"
                                label="Issued before"
                                @save="updateTo"
                                show-clear
                                hide-details
                                dense
                                :loading="loading"
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-btn
                                class="secondary"
                                block
                                outlined
                                @click="updateRouteWithFilters"
                                :loading="loading"
                                >Update</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <PaginationCard
                    :page="filter.page"
                    :data="data"
                    :limit="filter.limit"
                    :loading="loading"
                    v-on:updateLimit="updateLimit"
                    v-on:updatePage="updatePage"
                >
                    <v-simple-table fixed-header>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Customer</th>
                                    <th class="text-center">Issue Date</th>
                                    <th class="text-center">Period Total</th>
                                    <th class="text-center">
                                        Payments/Adjustments
                                    </th>
                                    <th class="text-center">
                                        Outstanding balance
                                    </th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in data.data" :key="i">
                                    <td>{{ item.i_invoice }}</td>
                                    <td>
                                        <router-link
                                            :to="
                                                '/customer/' + item.i_customer +'/summary'
                                            "
                                            >{{
                                                item.customer_name
                                            }}</router-link
                                        >
                                    </td>
                                    <td class="text-center">
                                        {{ item.issue_date }}
                                    </td>
                                    <td class="text-center">
                                        <Currency
                                            :value="item.amount_net"
                                            hide-tooltip
                                        />
                                    </td>
                                    <td class="text-center">
                                        <Currency
                                            :value="item.amount_paid"
                                            hide-tooltip
                                        />
                                    </td>
                                    <td class="text-center">
                                        <Currency
                                            :value="
                                                item.amount_net -
                                                item.amount_paid
                                            "
                                            hide-tooltip
                                        />
                                    </td>
                                    <td>{{ item.invoice_status }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </PaginationCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PaginationCard from '../../../components/cards/templates/PaginationCard';
import Currency from '../../../components/pieces/Currency';
import DatePicker from '../../../components/pieces/Forms/DatePicker';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'UnpaidInvoiceList',
    mixins: [apiMixin],
    data: () => ({
        filter: {
            limit: 10,
            page: 1,
            from: '',
            to: '',
            status: 'COMPLETED',
            statusOptions: [
                'ALL',
                'STARTED',
                'AUTHORIZED',
                'COMPLETED',
                'FAILED',
                'CANCELLED',
                'VOIDED',
            ],
        },
        menu: '',
        loading: false,
        data: [],
    }),
    components: {
        DatePicker,
        Currency,
        PaginationCard,
    },
    methods: {
        async update() {
            this.loading = true;
            const params = {
                limit: this.filter.limit,
                page: this.filter.page,
            };
            if (this.filter.from) params.from = this.filter.from;
            if (this.filter.to) params.to = this.filter.to;
            const response = await this.Api.send('post','admin/invoice/list', params);
            this.loading = false;
            if (response.success) {
                this.updateData(response.data);
            } else {
                this.updateFailed(response.error, response.errorDetail);
            }
        },
        updatePage(data) {
            this.filter.page = data;
            this.update();
        },
        updateLimit(data) {
            this.filter.limit = data;
            this.filter.page = 1;
            this.update();
        },
        updateFrom(data) {
            this.filter.from = data;
        },
        updateTo(data) {
            this.filter.to = data;
        },
        updateStatus(data) {
            this.filter.status = data;
            this.update();
        },
        updateData(data) {
            this.data = data;
        },
        updateFailed() {
            this.data = [];
        },
        updateRouteWithFilters() {
            let query = {};
            let update = false;
            if (this.filter.to) query.to = this.filter.to;
            if (this.filter.to && this.filter.to !== this.$route.query.to) {
                update = true;
            } else if (!this.filter.to && this.$route.query.to) {
                update = true;
            }
            if (this.filter.from) query.from = this.filter.from;
            if (
                this.filter.from &&
                this.filter.from !== this.$route.query.from
            ) {
                query.from = this.filter.from;
                update = true;
            } else if (!this.filter.from && this.$route.query.from) {
                update = true;
            }
            if (update)
                this.$router.push({
                    name: 'UnpaidInvoiceReport',
                    query: query,
                });
        },
    },
    watch: {
        $route(to) {
            this.filter.to = to.query.to ? to.query.to : '';
            this.filter.from = to.query.from ? to.query.from : '';
            this.update();
        },
    },
    created() {
        this.filter.to = this.$route.query.to ? this.$route.query.to : '';
        this.filter.from = this.$route.query.from ? this.$route.query.from : '';
        this.update();
    },
};
</script>
